<template>
  <b-tabs v-model="selectedDataTypeIndex" @input="loadData">
    <b-field
      v-if="this.filterBy !== 'tcp_session_id'"
      position="is-bottom"
      label="Par défaut, les données des 12 heures qui précèdent la dernière connexion sont affichées."
    >
    </b-field>
    <b-field grouped>
      <b-field v-if="showItemsPerPageField()" label="Nombre d'éléments par page">
        <b-select v-model="itemsPerPage" expanded @input="loadCounters">
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </b-select>
      </b-field>
      <b-field
        label="Filtré par"
        v-if="selectedDataTypeIndex !== iotLogTabIndex && selectedDataTypeIndex !== otaTabIndex"
      >
        <b-select v-model="filterBy" @input="loadData">
          <option value="occurred_at" :disabled="selectedDataTypeIndex === deviceErrorTabIndex">Occurred at</option>
          <option value="added_at">Added at</option>
          <option value="tcp_session_id" :disabled="selected === null">TCP Session of selected row</option>
        </b-select>
      </b-field>
      <b-field
        label="Sévérité"
        v-if="selectedDataTypeIndex === iotLogTabIndex || selectedDataTypeIndex === deviceLogTabIndex"
      >
        <b-select v-model="severity" @input="loadData">
          <option value="ALL">ALL</option>
          <option value="DEBUG" v-if="selectedDataTypeIndex === iotLogTabIndex">DEBUG</option>
          <option value="INFO" v-if="selectedDataTypeIndex === iotLogTabIndex">INFO</option>
          <option value="WARN" v-if="selectedDataTypeIndex === iotLogTabIndex">WARN</option>
          <option value="ERROR">ERROR</option>
        </b-select>
      </b-field>
      <b-field v-if="selectedDataTypeIndex !== otaTabIndex && this.filterBy !== 'tcp_session_id'" label="Depuis :">
        <div>
          <div class="columns">
            <div class="column" style="padding-bottom: 0">
              <b-radio name="since" v-model="fromDateOption" native-value="DATE" @input="loadData">le</b-radio>
            </div>
            <div class="column" style="padding-bottom: 0">
              <b-datetimepicker
                v-if="fromDateOption === 'DATE'"
                v-model="fromDate"
                placeholder="Type or select a date..."
                icon="calendar-today"
                @input="loadData"
                editable
              >
              </b-datetimepicker>
            </div>
          </div>
          <div>
            <b-radio name="since" v-model="fromDateOption" native-value="NOW" @input="loadData">maintenant</b-radio>
          </div>
        </div>
      </b-field>
      <b-field v-if="selectedDataTypeIndex !== otaTabIndex && this.filterBy !== 'tcp_session_id'" label="Jusque :">
        <div>
          <div class="columns">
            <div class="column" style="padding-bottom: 0">
              <b-radio name="to" v-model="toDateOption" native-value="DATE" @input="loadData">le</b-radio>
            </div>
            <div class="column" style="padding-bottom: 0">
              <b-datetimepicker
                v-if="toDateOption === 'DATE'"
                v-model="toDate"
                placeholder="Type or select a date..."
                icon="calendar-today"
                @input="loadData"
                editable
              >
              </b-datetimepicker>
            </div>
          </div>
          <div>
            <b-radio name="to" v-model="toDateOption" native-value="NOW" @input="loadData">maintenant</b-radio>
          </div>
        </div>
      </b-field>
      <b-field label="Exporter">
        <button class="button is-primary" @click="downloadCsv">.CSV</button>
      </b-field>
      <b-field label="Analyse">
        <button class="button is-primary" @click="showCreateAnalysisModal">Créer</button>
      </b-field>
    </b-field>
    <CreateOrUpdateAnalysisModal
      :isActive="isCreateAnalysisModalActive"
      :closeModal="() => (this.isCreateAnalysisModalActive = false)"
      :analysisPublisher="analysisPublisher"
      :deviceId="this.device.id"
    >
    </CreateOrUpdateAnalysisModal>
    <b-tab-item v-for="dataType in dataTypes" :label="dataType.label" :key="dataType.label">
      <div style="height: 40px">
        <h2 class="subtitle" style="display: inline">List of {{ dataType.label.toLowerCase() }}</h2>
        <button class="button is-primary" @click="refreshData" style="float: right">Refresh</button>
      </div>
      <div v-if="dataType.label === 'OTA'" class="columns">
        <div class="column is-1">
          <label class="label">Firmware :</label>
        </div>
        <div class="column is-1">
          <b-select placeholder="Select..." v-model="selectedFirmware">
            <option v-for="option in firmwaresVersion" :value="option.fullVersion" :key="option.fullVersion">
              {{ option.fullVersion }}
            </option>
          </b-select>
        </div>
        <div class="column is-1">
          <button class="button is-primary" @click="confirmCreateOta" :disabled="selectedFirmware === null">
            Create OTA
          </button>
        </div>
        <div class="column is-1">
          <button class="button is-primary" @click="confirmDeleteOta" :disabled="activeOta === null">
            Delete active OTA
          </button>
        </div>
      </div>
      <div v-if="dataType.label === 'Logs (boitier)'">
        <b-message v-if="dataType.summary" title="Résumé" type="is-info" :closable="false">
          <b-field grouped group-multiline>
            <div class="control summary" v-for="(value, key) in dataType.summary" :key="key">
              <div>{{ key }}</div>
              <div class="summary-value" v-for="(sous_value, key) in value" :key="key">
                <b-taglist attached>
                  <b-tag type="is-dark">{{ key }}</b-tag>
                  <b-tag type="is-info">{{ sous_value }}</b-tag>
                </b-taglist>
              </div>
            </div>
            <div v-if="severity === 'ERROR'">
              <b-taglist attached>
                <b-tag type="is-dark">Nombre CSQ mauvais (inf à 110)</b-tag>
                <b-tag type="is-warning">{{ dataType.occuranciesErrorGprsLog }}</b-tag>
              </b-taglist>
            </div>
            <div v-if="severity === 'ALL'">
              <b-taglist attached>
                <b-tag type="is-dark">Puissance signal CSQ</b-tag>
                <b-tag type="is-warning">{{ dataType.averageGprsLog }}%</b-tag>
              </b-taglist>
            </div>
          </b-field>
        </b-message>
      </div>
      <div v-for="c in dataType.chartData" :key="c">
        <line-chart v-if="dataType.viewType === CHART_DATATYPE" :data="c" :messages="{ empty: 'Aucune donnée' }">
        </line-chart>
        <column-chart
          v-if="dataType.viewType === COLUMN_CHART_DATATYPE"
          :stacked="true"
          :data="c"
          :messages="{ empty: 'Aucune donnée' }"
        >
        </column-chart>
      </div>
      <b-table
        v-if="!dataType.viewType || dataType.viewType === TABLE_DATATYPE"
        @page-change="trackPage"
        :selected.sync="selected"
        :data="dataType.data"
        :loading="dataType.loading"
        paginated
        :detailed="dataType.detailed"
        :show-detail-icon="dataType.detailed"
        :perPage="itemsPerPage"
        :total="dataType.data.length"
        :narrowed="true"
        @sort="onSort"
        backend-sorting
      >
        <b-table-column v-slot="props" v-for="(column, index) in dataType.columns" :key="index" v-bind="column">
          <span v-html="props.row[column.field]"></span>
        </b-table-column>
        <template v-slot:detail="props" v-if="dataType.detailed">
          <p>
            <span class="tag is-info is-medium">{{ props.row.detailedTable.title }}</span>
          </p>
          <br />
          <div class="detail-tables">
            <b-table
              v-for="(table, index) in props.row.detailedTable.tables"
              :key="index"
              bordered
              striped
              :columns="table.columns"
              :data="table.data"
            >
            </b-table>
          </div>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>Pas de données pour les filtres sélectionnés.</p>
            </div>
          </section>
        </template>
      </b-table>
      <template>
        <section class="section" v-if="activeOta">
          <div class="columns">
            <div class="column is-1">
              <b-checkbox v-model="forceOta">Force OTA</b-checkbox>
            </div>
            <div class="column is-1">
              <div class="button is-primary" @click="updateDeviceConf()">Enregistrer</div>
            </div>
          </div>
        </section>
      </template>
    </b-tab-item>
  </b-tabs>
</template>

<script>
import CreateOrUpdateAnalysisModal from "Components/devices/CreateOrUpdateAnalysisModal";
import { SET_ERROR_ACTION } from "Stores/message";
import { uploadDeviceConf } from "Api/device";
import {
  GET_COUNTS_BY_DEVICE_AND_SESSION_ID,
  GET_BEACONS_BY_DEVICE_INSTANCE_ID,
  GET_DATA_ERROR_BY_DEVICE_INSTANCE_ID,
  GET_PULLOUT_BY_DEVICE_INSTANCE_ID,
  GET_BATTERY_LEVEL_BY_DEVICE_INSTANCE_ID,
  GET_NETWORK_DATA_BY_DEVICE_INSTANCE_ID,
  GET_TEMPERATURE_LEVEL_BY_DEVICE_INSTANCE_ID,
  GET_LOG_BY_DEVICE_INSTANCE_ID,
  GET_LOG_V2_BY_DEVICE_INSTANCE_ID,
  GET_IOT_LOG,
  GET_GPIO_TIMER_BY_DEVICE_INSTANCE_ID,
  GET_DEVICE_EVENT_BY_DEVICE_INSTANCE_ID,
  GET_OTA_BY_MAC,
  GET_LOCATION_BY_DEVICE_INSTANCE_ID,
  LOG_DEFINITION
} from "Stores/device-data";
import { mapState, mapActions } from "vuex";
import { FETCH_FIRMWARES_VERSION_ACTION } from "Stores/devices";
import { CREATE_OTA_ACTION, DELETE_OTA_ACTIVE_ACTION } from "Stores/ota";
import { Base64 } from "js-base64";
import { dateToLocaleString, formatToRFC3339, stringToDate } from "Utils";

const NULL_DATE = "N/A";
const CHART_DATATYPE = "CHART";
const COLUMN_CHART_DATATYPE = "COLUMN_CHART";
const TABLE_DATATYPE = "TABLE";
const fmtError = error => message => ({ error, message });
const fmtErrorMessage = entity => `Can't retrieve the ${entity}.<br />Please contact the support.<br />`;
const fmtRow = data =>
  data.map(d => {
    if (d.added_at_date) {
      return d;
    }

    const oat = stringToDate(d.occurred_at);
    const aat = stringToDate(d.added_at);
    if (d.last_packet_date) {
      d.last_packet_date_date = null;
      if (d.last_packet_date !== NULL_DATE) {
        const lastPacketDate = stringToDate(d.last_packet_date);
        d.last_packet_date_date = lastPacketDate;
        d.last_packet_date = lastPacketDate.toLocaleString();
      }
    }
    if (d.start_time) {
      d.start_time_date = null;
      if (d.start_time !== NULL_DATE) {
        const startTime = stringToDate(d.start_time);
        d.start_time_date = startTime;
        d.start_time = startTime.toLocaleString();
      }
    }
    if (d.end_time) {
      d.end_time_date = null;
      if (d.end_time !== NULL_DATE) {
        const endTime = stringToDate(d.end_time);
        d.end_time_date = endTime;
        d.end_time = endTime.toLocaleString();
      }
    }
    d.occurred_at = oat.toLocaleString();
    d.added_at = aat.toLocaleString();
    d.added_at_date = aat;
    d.occurred_at_date = oat;
    d.data_frames_count = "-";
    d.nack_count = "-";
    d.gps_count = "-";
    d.pullout_count = "-";
    d.temperature_count = "-";
    d.battery_level_count = "-";
    d.log_count = "-";
    d.log_v2_count = "-";
    d.network_data_count = "-";
    d.gpio_timer_count = "-";
    d.beacon_count = "-";
    d.last_data_added_at = "-";
    d.countersFetched = false;
    return d;
  });

const column = field => label => additionalProps => ({
  field,
  label,
  ...additionalProps
});

const occurredAtCol = column("occurred_at")("Occurred at")({ sortable: true });
const addedAtCol = column("added_at")("Added at")({ sortable: true });
const dataTypeColumns = customColumns => customColumns.concat([occurredAtCol, addedAtCol]);

const findMetadataValue = metadata => key => {
  const meta = metadata.find(m => m.key === key);
  return meta ? meta.value : null;
};

const metadataLabelFormatters = {
  OTA_PACKET: metadata =>
    `<span class="tag is-info is-medium">Packet ${findMetadataValue(metadata)("packetNumber")}</span>`,
  CONNECT: metadata => {
    const unknownFirm = findMetadataValue(metadata)("unknown_firmware_id");

    if (unknownFirm) {
      return `<span class="tag is-danger is-medium">Unknown firmware</span>`;
    }

    const firm = findMetadataValue(metadata)("firmware_name");

    if (firm) {
      return `<span class="tag is-info is-medium">${firm}</span>`;
    }

    return `<span class="tag is-warning is-medium">No firmware</span>`;
  },
  CONNECT_ACK: metadata => {
    let tags = `<span class="tag is-info is-medium">${metadata.length} values</span>`;
    const ota = findMetadataValue(metadata)("ota");

    if (ota === "true") {
      tags += `&nbsp;<span class="tag is-dark is-medium">OTA</span>`;
    }

    return tags;
  },
  DATA: metadata => {
    let tags = "";

    const ack = findMetadataValue(metadata)("ack");

    if (ack === "true") {
      tags += `<span class="tag is-success is-medium">ACK</span>&nbsp;`;
    } else {
      tags += `<span class="tag is-warning is-medium">NACK</span>&nbsp;`;
    }

    const gps = findMetadataValue(metadata)("receivedLocations");

    if (gps && gps !== "0") {
      tags += `<span class="tag is-dark is-medium">GPS</span>&nbsp;`;
    }

    const log = findMetadataValue(metadata)("receivedLogs");

    if (log && log !== "0") {
      tags += `<span class="tag is-normal is-medium">LOG</span>&nbsp;`;
    }

    const debug = findMetadataValue(metadata)("debug");
    if (debug && debug !== "0") {
      tags += `<span class="tag is-info is-medium">DEBUG</span>&nbsp;`;
    }

    const beacon = findMetadataValue(metadata)("receivedBeacons");
    if (beacon && beacon !== "0") {
      tags += `<span class="tag is-info is-medium">BLE</span>&nbsp;`;
    }

    const decodeErrors = findMetadataValue(metadata)("decodeErrors");
    if (decodeErrors && decodeErrors !== "0") {
      tags += `<span class="tag is-danger is-medium">DEC</span>&nbsp;`;
    }

    return tags;
  }
};

const metadataLabelFormatter = eventName => {
  const formatter = metadataLabelFormatters[eventName];
  return formatter ? formatter : metadata => `<span class="tag is-info is-medium">${metadata.length} values</span>`;
};

const sortDataForChart = (data, filterByField) => {
  const filterDateField = filterByField === "occurred_at" ? "occurred_at_date" : "added_at_date";
  return data.sort((a, b) => {
    if (a[filterDateField] === b[filterDateField]) {
      return 0;
    } else if (a[filterDateField] > b[filterDateField]) {
      return 1;
    } else {
      return -1;
    }
  });
};

const MANAGED_QUERY_PARAMS = [
  { field: "selectedDataTypeIndex", fmt: parseInt },
  { field: "itemsPerPage", fmt: parseInt },
  {
    field: "fromDate",
    fmt: v => stringToDate(v)
  },
  { field: "toDate", fmt: v => stringToDate(v) },
  "filterBy",
  "fromDateOption",
  "toDateOption",
  "severity"
];

const sortData = (data, field, order) => {
  const f = order === "desc" ? -1 : 1;
  if (field === "occurred_at") {
    field = "occurred_at_date";
  }
  if (field === "added_at") {
    field = "added_at_date";
  }
  if (field === "last_packet_date") {
    field = "last_packet_date_date";
  }
  if (field === "start_time") {
    field = "start_time_date";
  }
  if (field === "end_time") {
    field = "end_time_date";
  }
  return data.sort((a, b) => {
    if (a[field] === b[field]) {
      return 0;
    } else if (a[field] > b[field]) {
      return f;
    } else {
      return -1 * f;
    }
  });
};

let cachedDeviceEvents = null;

var loadDataTimeout = null;
export default {
  components: { CreateOrUpdateAnalysisModal },
  props: ["device"],
  data() {
    return {
      isCreateAnalysisModalActive: false,
      activeOta: null,
      fromDateOption: "DATE",
      toDateOption: "NOW",
      deviceLogTabIndex: 11,
      iotLogTabIndex: 12,
      deviceErrorTabIndex: 16,
      otaTabIndex: 17,
      selectedFirmware: null,
      firstItemIndex: 0,
      itemsPerPage: 50,
      selectedDataTypeIndex: 0,
      filterBy: "occurred_at",
      severity: "ALL",
      forceOta: this.device.force_ota,
      fromDate: null,
      toDate: null,
      selected: null,
      CHART_DATATYPE: CHART_DATATYPE,
      COLUMN_CHART_DATATYPE: COLUMN_CHART_DATATYPE,
      TABLE_DATATYPE: TABLE_DATATYPE,
      dataTypes: [
        {
          action: query =>
            this.getCachedDeviceEvents(query).then(({ data }) => ({
              data: data.filter(({ name }) => name === "CONNECT")
            })),
          loading: true,
          data: [],
          label: "Sessions",
          columns: dataTypeColumns([
            column("tcp_session_id_label")("TCP Session ID")(),
            column("data_frames_count")("Data frames")(),
            column("nack_count")("NACK frames")(),
            column("gps_count")("GPS")(),
            column("pullout_count")("PO")(),
            column("temperature_count")("Temp.")(),
            column("battery_level_count")("Bat. levels")(),
            column("log_count")("Logs")(),
            column("log_v2_count")("LogsV2")(),
            column("network_data_count")("Network")(),
            column("gpio_timer_count")("GPIO")(),
            column("beacon_count")("Beacons")(),
            column("last_data_added_at")("Last data added at")()
          ])
        },
        {
          action: query =>
            this.getCachedDeviceEvents(query).then(({ data }) => {
              data = data
                .filter(d => d.name === "CONNECT")
                .reduce((a, firmware) => {
                  const { metadata, occurred_at } = firmware;
                  const find = findMetadataValue(metadata);
                  const name = find("firmware_name");
                  const version = find("firmware_version");
                  const connectionDateString = stringToDate(occurred_at).toLocaleString();

                  if (a.length === 0) {
                    firmware.name = name;
                    firmware.version = version;
                    firmware.countTcpSession = 1;
                    firmware.firstConnectionString = connectionDateString;
                    firmware.lastConnectionString = connectionDateString;
                    firmware.firstConnection = occurred_at;
                    firmware.lastConnection = occurred_at;
                    a.push(firmware);
                  } else {
                    const last = a[a.length - 1];

                    if (last.name === name) {
                      last.lastConnection = occurred_at;
                      last.lastConnectionString = connectionDateString;
                      last.countTcpSession++;
                    } else {
                      firmware.name = name;
                      firmware.version = version;
                      firmware.countTcpSession = 1;
                      firmware.firstConnectionString = connectionDateString;
                      firmware.lastConnectionString = connectionDateString;
                      firmware.firstConnection = occurred_at;
                      firmware.lastConnection = occurred_at;
                      a.push(firmware);
                    }
                  }

                  return a;
                }, []);
              return { data };
            }),
          loading: true,
          data: [],
          label: "Firmwares",
          columns: [
            column("countTcpSession")("Nombre de sessions TCP")(),
            column("name")("Name")(),
            column("version")("version")(),
            column("firstConnectionString")("Première connexion")(),
            column("lastConnectionString")("Dernière connexion")()
          ]
        },
        {
          action: this.getLocations,
          loading: true,
          data: [],
          label: "Locations",
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("latitude")("Latitude")(),
            column("longitude")("Longitude")(),
            column("accelerometer")("Accel.")(),
            column("dop")("DOP")(),
            column("slope")("Slope")(),
            column("satellites")("Sat.")()
          ])
        },
        {
          action: query =>
            this.getBeacons(query).then(({ data }) => {
              data.forEach(d => (d.minor = `${d.minor} (hex: ${d.minor.toString(16)})`));
              return { data };
            }),
          loading: true,
          data: [],
          label: "Beacons",
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("beacon_uuid")("UUID")(),
            column("minor")("Minor")(),
            column("rssi")("RSSI")(),
            column("accelerometer")("Accelero")()
          ])
        },
        {
          action: this.getPullouts,
          loading: true,
          data: [],
          label: "Pullouts",
          columns: dataTypeColumns([column("tcp_session_id")("TCP Session ID")(), column("po_one")("Pullout")()])
        },
        {
          action: this.getBatteryLevels,
          loading: true,
          data: [], // need for csv
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("internal_battery_level")("Etat de santé (SOH)")(),
            column("external_battery_level")("Autonomie restante (SOC)")()
          ]), // need for csv
          chartData: [],
          viewType: CHART_DATATYPE,
          label: "Battery (graphe)",
          buildChartData: (data, filterByField) => {
            data = sortDataForChart(data, filterByField);
            let externalBatteryData = {};
            let internalBatteryData = {};
            let voltage = {};
            let voltageCell1 = {};
            let voltageCell2 = {};
            let voltageCell3 = {};
            let batteryLevelBasedOnVoltage = {};
            let batteryLevelBasedOnVoltageV2 = {};
            let amperage = {};

            function getBatteryLevelFromVoltage(v) {
              // la batterie est calculée sur la tension.
              // le 0-100% de batterie, c'est le % entre 8.6v et 12.6v
              const maxVoltage = 12.6;
              const minVoltage = 8.6;

              if (v === null) {
                return null;
              }

              if (v >= maxVoltage) {
                return 100.0;
              }
              if (v <= minVoltage) {
                return 0;
              }

              return (((v - minVoltage) / (maxVoltage - minVoltage)) * 100).toFixed(2);
            }

            function getBatteryLevelFromVoltageV2(v1, v2, v3, voltage) {
              const maxVoltage = 12.6;
              const minVoltage = 7.9;

              if (voltage === null || v1 === null || v2 === null || v3 === null) {
                return null;
              }

              let v = Math.min(v1, v2, v3);

              // avoid i2c problem, if the sum of the 3 cells is more than 10% of the max voltage, we consider it's an i2c problem
              if (v1 + v2 + v3 > voltage * 1.1 || v1 + v2 + v3 < voltage * 0.9) {
                return null;
              }

              v *= 3; // 3 cells

              if (v >= maxVoltage) {
                return 100.0;
              }
              if (v <= minVoltage) {
                return 0;
              }

              return (((v - minVoltage) / (maxVoltage - minVoltage)) * 100).toFixed(2);
            }

            data.forEach(d => {
              const key = d[`${filterByField}_date`];
              externalBatteryData[key] = d.external_battery_level;
              internalBatteryData[key] = d.internal_battery_level;
              voltage[key] = d.voltage;
              batteryLevelBasedOnVoltage[key] = getBatteryLevelFromVoltage(d.voltage);
              batteryLevelBasedOnVoltageV2[key] = getBatteryLevelFromVoltageV2(
                d.voltage_cell_1,
                d.voltage_cell_2,
                d.voltage_cell_3,
                d.voltage
              );
              amperage[key] = d.current;

              if (d.voltage_cell_1 && d.voltageCell1 != 0) {
                voltageCell1[key] = d.voltage_cell_1;
              }
              if (d.voltage_cell_2 && d.voltageCell2 != 0) {
                voltageCell2[key] = d.voltage_cell_2;
              }
              if (d.voltage_cell_3 && d.voltageCell3 != 0) {
                voltageCell3[key] = d.voltage_cell_3;
              }
            });

            const retval = [];
            retval.push([
              { name: "SOC recalculé via la tension (8.6 - 12.6)", data: batteryLevelBasedOnVoltage },
              { name: "Etat de santé (SOH)", data: internalBatteryData },
              { name: "SOC estimé batterie", data: externalBatteryData, color: "lightblue" },
              {
                name: "SOC recalculé via la tension de la plus basse cellule (7.9 - 12.6)",
                data: batteryLevelBasedOnVoltageV2,
                color: "pink"
              }
            ]);

            retval.push([
              { name: "Tension Globale", data: voltage },
              { name: "Tension cellule 1", data: voltageCell1 },
              { name: "Tension cellule 2", data: voltageCell2, color: "lightblue" },
              { name: "Tension celulle 3", data: voltageCell3 }
            ]);

            retval.push([{ name: "Ampérage", data: amperage }]);

            return retval;
          }
        },
        {
          action: this.getBatteryLevels,
          loading: true,
          data: [],
          label: "Battery data (tableau)",
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("internal_battery_level")("Etat de santé (SOH)")(),
            column("external_battery_level")("Autonomie restante (SOC)")(),
            column("current")("Courant")(),
            column("voltage")("Tension")(),
            column("voltage_cell_1")("Tension Cell 1")(),
            column("voltage_cell_2")("Tension Cell 2")(),
            column("voltage_cell_3")("Tension Cell 3")()
          ])
        },
        {
          action: query =>
            this.getTemperatureLevels(query).then(({ data }) => {
              data.forEach(temperature => (temperature.value = Base64.decode(temperature.value)));
              return { data };
            }),
          loading: true,
          data: [], // need for csv
          columns: dataTypeColumns([column("tcp_session_id")("TCP Session ID")(), column("value")("Value")()]), // need for csv
          chartData: [],
          label: "Temperature",
          viewType: CHART_DATATYPE,
          buildChartData: (data, filterByField) => [
            sortDataForChart(data, filterByField).map(d => [d[`${filterByField}_date`], d.value])
          ]
        },
        {
          action: query => this.getBeacons(query),
          loading: true,
          data: [], // need for csv
          columns: dataTypeColumns([column("tcp_session_id")("TCP Session ID")(), column("value")("Value")()]), // need for csv
          chartData: [],
          label: "Carburant",
          viewType: CHART_DATATYPE,
          buildChartData: (data, filterByField) => {
            const groupedByBeacon = data.reduce((a, d) => {
              let r = a[d.beacon_uuid] || {};
              const key = d[`${filterByField}_date`];
              r[key] = parseInt(d.minor, 10) / 10; // le fuel est envoyé dans le minor en decilitre
              a[d.beacon_uuid] = r;
              return a;
            }, {});
            const retval = [];
            for (const key in groupedByBeacon) {
              if (key.startsWith("2")) {
                // les beacons fuel commencent par le chiffre "2" dans leur uuid
                retval.push([{ name: key, data: groupedByBeacon[key] }]);
              }
            }
            return retval;
          }
        },
        {
          action: this.getLogs,
          loading: true,
          data: [], // need for csv
          columns: [], // need for csv
          chartData: [],
          viewType: COLUMN_CHART_DATATYPE,
          label: "Network (graphe)",
          buildChartData: data => {
            let networkGPRS = {};
            let networkLTE = {};
            let networkError = {};
            networkLTE["default"] = {};

            const groupedByTcpSessionID = data.reduce((a, d) => {
              d["val_error"] = this.getValErrorFromData(d);
              d["module_error"] = this.getModuleErrorFromData(d);
              let r = a[d.tcp_session_id] || {};
              if (
                (d["module_error"] === "GPRS_ID" || d["module_error"] === "LTE_ID") &&
                ((d["i2c_reset"] >= 100 && d["i2c_reset"] <= 131) || d["i2c_reset"] == 199)
              ) {
                const val = ((d["i2c_reset"] - 100) / 31) * 100;
                if (val != 0) {
                  r["csq"] = val.toFixed(2);
                  r["k"] = d.occurred_at;
                  if (d["i2c_reset"] == 199) {
                    r["csq"] = "5";
                  }
                }
              }
              if (d["val_error"] === "GPRS_NETWORK") {
                r["network"] = "GPRS";
              }
              if (d["val_error"] === "LTE_NETWORK") {
                r["network"] = "LTE";
              }
              // nouveau firm, toutes la donnée en un seul log
              if (d["not_acked_frames"] == 27 && d["i2c_reset"] == 76) {
                a[d.occurred_at] = {
                  network: "LTE",
                  csq: d["watchdog_reboot"] > 100 ? 5 : d["watchdog_reboot"],
                  band: d["bad_eeprom"],
                  k: d.occurred_at
                };
                return a;
              }
              if (
                (d["not_acked_frames"] == 27 && d["i2c_reset"] == 77) ||
                (d["not_acked_frames"] == 1 && d["i2c_reset"] == 100)
              ) {
                a[d.occurred_at] = {
                  network: "GPRS",
                  csq: d["watchdog_reboot"],
                  k: d.occurred_at
                };
                return a;
              }

              if (
                (d["not_acked_frames"] == 21 && d["i2c_reset"] == 30) ||
                (d["not_acked_frames"] == 27 && d["i2c_reset"] == 36) ||
                (d["not_acked_frames"] == 1 && d["i2c_reset"] == 36)
              ) {
                a[d.occurred_at] = {
                  network: "ERROR",
                  csq: 100.0,
                  k: d.occurred_at
                };
                return a;
              }
              a[d.tcp_session_id] = r;
              return a;
            }, {});

            for (const key in groupedByTcpSessionID) {
              if (groupedByTcpSessionID[key].csq) {
                switch (groupedByTcpSessionID[key].network) {
                  case "ERROR":
                    networkLTE["default"][groupedByTcpSessionID[key].k] = null;
                    networkGPRS[groupedByTcpSessionID[key].k] = null;
                    networkError[groupedByTcpSessionID[key].k] = groupedByTcpSessionID[key].csq;
                    break;
                  case "LTE":
                    if (groupedByTcpSessionID[key].band && groupedByTcpSessionID[key].band > 0) {
                      if (networkLTE[groupedByTcpSessionID[key].band] === undefined) {
                        networkLTE[groupedByTcpSessionID[key].band] = {};
                      }
                      networkLTE[groupedByTcpSessionID[key].band][groupedByTcpSessionID[key].k] =
                        groupedByTcpSessionID[key].csq;
                    } else {
                      networkLTE["default"][groupedByTcpSessionID[key].k] = groupedByTcpSessionID[key].csq;
                    }
                    networkGPRS[groupedByTcpSessionID[key].k] = null;
                    networkError[groupedByTcpSessionID[key].k] = null;
                    break;
                  default:
                    networkLTE["default"][groupedByTcpSessionID[key].k] = null;
                    networkGPRS[groupedByTcpSessionID[key].k] = groupedByTcpSessionID[key].csq;
                    networkError[groupedByTcpSessionID[key].k] = null;
                    break;
                }
              }
            }
            const retval = [];

            retval.push([
              { name: "GPRS Network", data: networkGPRS, color: "#F39322" },
              { name: "Network Error", data: networkError, color: "black" }
            ]);

            const colors = {
              1: "#06b6c9",
              3: "#79a690",
              7: "#79a69e",
              20: "#4b949c",
              28: "#14a68d",
              default: "#59bab5"
            };
            for (const band in networkLTE) {
              retval[0].push({
                name: `LTE Network (${band !== "default" ? "B" + band : "default"})`,
                data: networkLTE[band],
                color: colors[band] || "#59bab5"
              });
            }

            return retval;
          }
        },
        {
          action: this.getNetworkData,
          loading: true,
          data: [],
          label: "Network Data (tableau)",
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("device_latitude")("device latitude")(),
            column("device_longitude")("device longitude")(),
            column("mcc")("MCC")(),
            column("mnc")("MNC")(),
            column("lac")("LAC")(),
            column("cell_id")("CellID")(),
            column("radio_access_technology")("Radio Access Technology")(),
            column("signal_quality")("Signal Quality")(),
            column("frequency_band")("Frequency Band")()
          ])
        },
        {
          action: query =>
            this.getLogs(query).then(({ data }) => {
              let summary = {};
              let valuesGprslog = [];
              let occuranciesErrorGprsLog = 0;
              let averageGprsLog = 0;
              data.map(l => {
                l["val_error"] = this.getValErrorFromData(l);
                l["module_error"] = this.getModuleErrorFromData(l);
                // Si je n'ai pas encore rencontré ce module_error, je l'ajoute à mon objet en tant que clé, et sa valeur est un objet, ayant pour clé la val_error et value 1 (une seule occurence)
                if (Object.keys(summary).indexOf(l["module_error"]) === -1) {
                  let tmp = {};
                  tmp[l["val_error"]] = 1;
                  summary[l["module_error"]] = tmp;
                }
                // Sinon, je parcours l'objet value de ce module_error, et je regarde si j'ai déjà croisé cette val_error
                else {
                  // Si je ne l'ai pas croisée, je l'ajoute
                  if (Object.keys(summary[l["module_error"]]).indexOf(l["val_error"]) === -1) {
                    summary[l["module_error"]][l["val_error"]] = 1;
                  } else {
                    // Sinon j'incrémente sa valeur
                    summary[l["module_error"]][l["val_error"]]++;
                  }
                }
                // Sur la liste des logs severity "ALL", on souhaite avoir une moyenne des logs entre 100 et 130, on stocke donc l'ensemble des valeurs
                if (
                  (l["module_error"] === "GPRS_ID" || l["module_error"] === "LTE_ID") &&
                  l["i2c_reset"] >= 100 &&
                  l["i2c_reset"] <= 131
                ) {
                  valuesGprslog.push(l["i2c_reset"]);
                }
                // Sur la liste des logs severity "ERROR", on souhaite avoir le nombre d'occurence des logs entre 100 et 110, on incrémente le compteur
                if (
                  (l["module_error"] === "GPRS_ID" || l["module_error"] === "LTE_ID") &&
                  l["i2c_reset"] >= 100 &&
                  l["i2c_reset"] <= 110
                ) {
                  occuranciesErrorGprsLog++;
                }
                return l;
              });
              if (valuesGprslog.length) {
                // calcul de la moyenne et passage en pourcentage
                averageGprsLog = Math.round(
                  ((valuesGprslog.reduce((a, b) => a + b, 0) / valuesGprslog.length - 100) * 100) / 31
                );
              }
              return { data, summary, occuranciesErrorGprsLog, averageGprsLog };
            }),
          loading: true,
          data: [],
          summary: {},
          occuranciesErrorGprsLog: 0,
          averageGprsLog: 0,
          label: "Logs (boitier)",
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("gprs_error")("GPRS errors")(),
            column("watchdog_reboot")("Watchdog reboot")(),
            column("bad_eeprom")("Bad EEPROM")(),
            column("not_acked_frames")("Module log brute")(), // colonne réutilisée pour un autre usage sur les dernières versions de Karnott
            column("i2c_reset")("Valeur Log brute")(), // colonne réutilisée pour un autre usage sur les dernières versions de Karnott
            column("module_error")("Module Log")(),
            column("val_error")("Valeur Log")()
          ])
        },
        {
          action: query => {
            let queryParam = {
              mac: this.device.noolitic_mac
            };

            if (query.params.from) {
              queryParam.fromDate = query.params.from;
            }

            if (query.params.to) {
              queryParam.toDate = query.params.to;
            }

            if (query.params.severity && query.params.severity !== "ALL") {
              queryParam.severity = query.params.severity;
            }

            return this.getIotLogs(queryParam).then(({ data }) => {
              return { data };
            });
          },
          loading: true,
          data: [],
          label: "Logs (serveur)",
          columns: [column("severity")("Level")(), column("occurred_at")("Date")(), column("message")("Message")()]
        },
        {
          action: query =>
            this.getLogsv2(query).then(({ data }) => {
              data.forEach(d => (d.severity = Base64.decode(d.severity)));
              return { data };
            }),
          loading: true,
          data: [],
          label: "Log V2",
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("severity")("Severity")(),
            column("module")("Module")(),
            column("code")("Code")(),
            column("val")("Valeur")()
          ])
        },
        {
          action: this.getGpioTimer,
          loading: true,
          data: [],
          label: "GPIO timers",
          columns: dataTypeColumns([column("tcp_session_id")("TCP Session ID")(), column("status")("Status")()])
        },
        {
          action: query =>
            this.getCachedDeviceEvents(query).then(({ data }) => ({
              data: data.map(d => {
                d.metadata_label = metadataLabelFormatter(d.name)(d.metadata);
                return d;
              })
            })),
          loading: true,
          data: [],
          label: "Device events",
          detailed: true,
          buildDetailedTable(data) {
            if (data.detailedTable) {
              return data;
            }

            return data.map(d => ({
              ...d,
              detailedTable: {
                title: "Metadata",
                tables: d.metadata
                  .reduce(
                    (acc, m, index) => {
                      if ((index + 1) % 3 === 0) {
                        acc[2].push(m);
                      } else if ((index + 1) % 2 === 0) {
                        acc[1].push(m);
                      } else {
                        acc[0].push(m);
                      }
                      return [acc[0], acc[1], acc[2]];
                    },
                    [[], [], []]
                  )
                  .filter(d => d.length > 0)
                  .map(d => ({ data: d, columns: [column("key")("clé")(), column("value")("valeur")()] }))
              }
            }));
          },
          columns: dataTypeColumns([
            column("tcp_session_id")("TCP Session ID")(),
            column("name")("Name")(),
            column("metadata_label")("Metadata")({ excluded: true })
          ])
        },
        {
          action: query =>
            this.getDataError(query).then(({ data }) => {
              data.forEach(err => (err.data = Base64.decode(err.data)));
              return { data };
            }),
          loading: true,
          data: [],
          label: "Data Error",
          columns: [column("tcp_session_id")("TCP Session ID")(), column("data")("Data")(), addedAtCol]
        },
        {
          action: () =>
            this.fetchFirmwaresVersion()
              .catch(e =>
                this.setError({
                  message: "Can't retrieve the firmwares.<br />Please contact the support.<br />",
                  error: e
                })
              )
              .then(() => this.getOtas(this.device.noolitic_mac))
              .then(({ data }) => {
                data.forEach(ota => {
                  ota.firmware_version = `${ota.firmware_major}.${ota.firmware_minor}.${ota.firmware_patch}`;

                  if (!ota.end_time) {
                    ota.end_time = NULL_DATE;
                  }

                  if (!ota.last_packet_date) {
                    ota.last_packet_date = NULL_DATE;
                  }

                  if (!ota.start_time) {
                    this.activeOta = ota;
                    ota.start_time = NULL_DATE;
                  } else {
                    // if the ota started more than an hour ago, it can be deleted again
                    var d = new Date(ota.start_time);
                    if (d.setHours(d.getHours() + 1) < Date.now()) {
                      this.activeOta = ota;
                    }
                  }

                  ota.packets = `${ota.last_packet_number} / ${ota.number_of_packets}`;
                });

                return { data };
              }),
          loading: true,
          data: [],
          label: "OTA",
          columns: [
            column("firmware_version")("Targetted Firmware")(),
            column("from_firmware_id")("From Firmware ID")(),
            column("start_time")("Début (occurred_at)")({ sortable: true }),
            column("end_time")("Fin")({ sortable: true }),
            column("packets")("Paquets")(),
            column("last_packet_date")("Date du dernier paquet")({ sortable: true }),
            addedAtCol
          ]
        }
      ],
      analysisUpdater: null
    };
  },
  computed: mapState({
    firmwares: state => state.devices.firmwares,
    firmwaresVersion: state => state.devices.firmwaresVersion
  }),
  methods: {
    updateDeviceConf() {
      const payload = {
        id: this.device.id,
        force_ota: this.forceOta
      };
      const { apiClient } = this.$store.getters;
      uploadDeviceConf(apiClient)(payload).then(r => {
        if (r.status === 204) {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: "Changements enregistrés !",
            position: "is-bottom-right"
          });
        }
      });
    },
    setDefaultSort(data, columns) {
      const fields = ["occurred_at", "added_at", "start_time"];
      for (let i in fields) {
        const field = fields[i];
        const isFieldExist = columns.find(c => c.field === field);
        if (isFieldExist) {
          return sortData(data, `${field}_date`, "desc");
        }
      }
      return data;
    },
    showItemsPerPageField() {
      // hide field for temperature & battery tabs because of chart
      return this.selectedDataTypeIndex !== 5 && this.selectedDataTypeIndex !== 6;
    },
    getModuleErrorFromData(data) {
      let naf = LOG_DEFINITION[data.not_acked_frames];
      if (naf) {
        return naf.label;
      }
      return "not registered";
    },
    getValErrorFromData(data) {
      let naf = LOG_DEFINITION[data.not_acked_frames];
      if (naf && naf.value && naf.value[data.i2c_reset]) {
        return naf.value[data.i2c_reset];
      }
      return "not registered";
    },
    confirmCreateOta() {
      let major, minor, patch;
      this.firmwaresVersion.forEach(fv => {
        if (fv.fullVersion === this.selectedFirmware) {
          major = fv.major;
          minor = fv.minor;
          patch = fv.patch;
        }
      });
      const params = {
        deviceInstanceID: this.device.id,
        major,
        minor,
        patch
      };
      this.createOta(params)
        .catch(e =>
          this.setError({ message: "Can't create the OTA.<br />Please contact the support.<br />", error: e })
        )
        .then(() => {
          if (this.toDateOption === "DATE") {
            this.addOneMinuteToEndDate();
          }

          this.loadData();
        });
    },
    confirmDeleteOta() {
      this.deleteOta(this.device.noolitic_mac)
        .catch(e =>
          this.setError({ message: "Can't delete the OTA.<br />Please contact the support.<br />", error: e })
        )
        .then(() => {
          if (this.toDateOption === "DATE") {
            this.addOneMinuteToEndDate();
          }
          this.activeOta = null;

          this.loadData();
        });
    },
    addOneMinuteToEndDate() {
      let now = new Date();
      now = new Date(now.getTime() - now.getTimezoneOffset() * 60000 + 1000 * 60);
      this.toDate = now;
    },
    onSort(field, order) {
      let { data } = this.dataTypes[this.selectedDataTypeIndex];
      this.dataTypes[this.selectedDataTypeIndex].data = sortData(data, field, order);
    },
    trackPage(pageNumber) {
      this.firstItemIndex = (pageNumber - 1) * this.itemsPerPage;
      this.loadCounters();
    },
    isSessionsTabItem() {
      return this.selectedDataTypeIndex === 0;
    },
    formatInputDates() {
      let { fromDate, toDate } = this;
      if (this.fromDateOption === "NOW") {
        fromDate = new Date();
      } else if (fromDate !== null && !(fromDate instanceof Date)) {
        fromDate = stringToDate(fromDate);
      } else if (fromDate === null) {
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 1);
      }
      this.fromDate = fromDate;

      if (this.toDateOption === "NOW") {
        toDate = new Date();
      } else if (toDate !== null && !(toDate instanceof Date)) {
        toDate = stringToDate(toDate);
      }
      this.toDate = toDate;

      return { fromDate, toDate };
    },
    loadData() {
      if (this.device === undefined || this.device.id === undefined) {
        return;
      }
      clearTimeout(loadDataTimeout);
      loadDataTimeout = setTimeout(() => {
        const dataType = this.dataTypes[this.selectedDataTypeIndex];

        this.updateUrl();
        let filterBy = this.filterBy;
        const filterByTcpSessionId = filterBy === "tcp_session_id";
        const otaTab = this.selectedDataTypeIndex === this.otaTabIndex;
        const dataErrorTab = this.selectedDataTypeIndex === this.deviceErrorTabIndex;
        const iotLogTab = this.selectedDataTypeIndex === this.iotLogTabIndex;
        const deviceLogTab = this.selectedDataTypeIndex === this.deviceLogTabIndex;
        let params;

        if (filterByTcpSessionId) {
          if (this.selected) {
            params = { tcpSessionId: this.selected.tcp_session_id };
            dataType.data = [];
          }
        } else {
          const { fromDate, toDate } = this.formatInputDates();
          this.selected = null;
          params = { from: fromDate, to: toDate };
        }

        // Occurred at and tcp session ID not available in OTA table
        if (otaTab && (filterBy === "occurred_at" || filterByTcpSessionId)) {
          filterBy = "added_at";
          this.filterBy = filterBy;
        }
        // occurred_at not available in data error table
        if (dataErrorTab && filterBy === "occurred_at") {
          filterBy = "added_at";
          this.filterBy = filterBy;
        }

        if (iotLogTab || deviceLogTab) {
          params.severity = this.severity;
        }

        dataType.loading = true;
        dataType
          .action({ id: this.device.id, filterBy, params })
          .catch(e => {
            dataType.loading = false;
            this.setError(fmtError(e)(fmtErrorMessage(dataType.label.toLowerCase())));
          })
          .then(result => {
            let data = this.setDefaultSort(fmtRow(result.data), dataType.columns);
            dataType.summary = result.summary;
            dataType.averageGprsLog = result.averageGprsLog;
            dataType.occuranciesErrorGprsLog = result.occuranciesErrorGprsLog;
            if (dataType.buildDetailedTable) {
              data = dataType.buildDetailedTable(data);
            }
            if (dataType.buildChartData) {
              dataType.chartData = dataType.buildChartData(data, filterBy);
            }

            return this.isSessionsTabItem() ? this.loadCountersOf(data) : data;
          })
          .then(data => {
            dataType.loading = false;
            dataType.data = data;
          });
      }, 500);
    },
    downloadCsv() {
      const { dataTypes, fromDate, selectedDataTypeIndex, toDate } = this;
      const dataType = dataTypes[selectedDataTypeIndex];
      const { columns, data } = dataType;
      const filteredColumns = columns.filter(c => !c.excluded);
      const csvHeaders = filteredColumns.map(c => c.label).join(";");
      const csvValues = data.map(d => filteredColumns.map(c => d[c.field] || "").join(";"));
      const content = [csvHeaders].concat(csvValues).join("\n");
      const blob = new Blob(["\uFEFF" + content], {
        type: "text/csv;charset=utf-8"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = dataType.label + formatToRFC3339(fromDate, false) + formatToRFC3339(toDate, false) + ".csv";
      a.click();
    },
    refreshData() {
      this.loading = true;
      this.loadData();
    },
    loadCounters() {
      this.updateUrl();

      if (this.isSessionsTabItem()) {
        const dataType = this.dataTypes[this.selectedDataTypeIndex];
        this.loadCountersOf(dataType.data).then(data => (dataType.data = data));
      }
    },
    loadCountersOf(data) {
      const fetchAndUpdate = async () => {
        const start = this.firstItemIndex;
        let end = start + this.itemsPerPage;

        if (end > data.length) {
          end = data.length;
        }

        const hasData = ({ countersFetched, tcp_session_id }) =>
          !countersFetched && tcp_session_id !== null && tcp_session_id !== undefined;

        const tcpSessionIds = [];

        for (let i = start; i < end; i++) {
          const item = data[i];

          if (hasData(item)) {
            tcpSessionIds.push(item.tcp_session_id);
          }
        }

        if (tcpSessionIds.length === 0) {
          return data;
        }

        const countersResult = await this.getDataCount({ id: this.device.id, tcpSessionIds });

        for (let i = start; i < end; i++) {
          const item = data[i];
          const result = countersResult.data.find(counter => counter.tcp_session_id === item.tcp_session_id);

          if (!result) {
            continue;
          }

          const reason = findMetadataValue(item.metadata)("reason");
          item.tcp_session_id_label = result.tcp_session_id;
          if (reason) {
            if (reason.startsWith("CONNECTION_REASON_")) {
              item.tcp_session_id_label = `<strong>${reason.substr("CONNECTION_REASON_".length)}</strong> - ${
                item.tcp_session_id_label
              }`;
            } else {
              item.tcp_session_id_label = `<strong>${reason}</strong> - ${item.tcp_session_id_label}`;
            }
          }
          item.data_frames_count = result.data_frames_count;
          item.nack_count = result.nack_count;
          item.gps_count = result.gps_count;
          item.pullout_count = result.pullout_count;
          item.temperature_count = result.temperature_count;
          item.battery_level_count = result.battery_level_count;
          item.log_count = result.log_count;
          item.log_v2_count = result.log_v2_count;
          item.network_data_count = result.network_data_count;
          item.gpio_timer_count = result.gpio_timer_count;
          item.beacon_count = result.beacon_count;
          const aat = result.last_data_added_at ? stringToDate(result.last_data_added_at) : null;
          item.last_data_added_at = aat ? aat.toLocaleString() : "-";
          item.countersFetched = true;
        }

        return data;
      };

      return fetchAndUpdate().catch(e =>
        this.setError(fmtError(e)(`Unable to count data for a TCP session: ${e.message}`))
      );
    },
    async getCachedDeviceEvents(query) {
      const getCopyResult = result => ({
        data: result.data.map(d => {
          const cpy = Object.assign({}, d);
          return cpy;
        })
      });

      if (cachedDeviceEvents && JSON.stringify(query) === JSON.stringify(cachedDeviceEvents.query)) {
        return getCopyResult(cachedDeviceEvents.result);
      }

      const { data } = await this.getDeviceEvents(query);
      const retval = data.map(event => {
        event.name = Base64.decode(event.name);

        try {
          event.metadata = JSON.parse(Base64.decode(event.metadata));
        } catch (e) {
          event.metadata = [];
        }

        return event;
      });

      cachedDeviceEvents = {
        query,
        result: { data: retval }
      };

      return getCopyResult(cachedDeviceEvents.result);
    },
    ...mapActions({
      getDataCount: GET_COUNTS_BY_DEVICE_AND_SESSION_ID,
      getLocations: GET_LOCATION_BY_DEVICE_INSTANCE_ID,
      getBeacons: GET_BEACONS_BY_DEVICE_INSTANCE_ID,
      getDataError: GET_DATA_ERROR_BY_DEVICE_INSTANCE_ID,
      getPullouts: GET_PULLOUT_BY_DEVICE_INSTANCE_ID,
      getBatteryLevels: GET_BATTERY_LEVEL_BY_DEVICE_INSTANCE_ID,
      getNetworkData: GET_NETWORK_DATA_BY_DEVICE_INSTANCE_ID,
      getTemperatureLevels: GET_TEMPERATURE_LEVEL_BY_DEVICE_INSTANCE_ID,
      getLogs: GET_LOG_BY_DEVICE_INSTANCE_ID,
      getLogsv2: GET_LOG_V2_BY_DEVICE_INSTANCE_ID,
      getIotLogs: GET_IOT_LOG,
      getGpioTimer: GET_GPIO_TIMER_BY_DEVICE_INSTANCE_ID,
      getOtas: GET_OTA_BY_MAC,
      getDeviceEvents: GET_DEVICE_EVENT_BY_DEVICE_INSTANCE_ID,
      setError: SET_ERROR_ACTION,
      fetchFirmwaresVersion: FETCH_FIRMWARES_VERSION_ACTION,
      createOta: CREATE_OTA_ACTION,
      deleteOta: DELETE_OTA_ACTIVE_ACTION
    }),
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      MANAGED_QUERY_PARAMS.map(prop => (prop.field ? prop.field : prop)).forEach(prop => {
        const value = prop === "fromDate" || prop === "toDate" ? dateToLocaleString(this[prop]) : this[prop];
        return (query[prop] = value);
      });

      this.$router.push({ path: this.device.id.toString(), query }).catch(() => {});
    },
    syncFromQueryParams(props) {
      props.forEach(prop => {
        const field = prop.field ? prop.field : prop;
        const value = this.$route.query[field];

        if (value) {
          this[field] = prop.fmt ? prop.fmt(value) : value;
        }
      });
    },
    analysisPublisher(callback) {
      this.analysisUpdater = callback;
      callback(null, null, null, this.fromDate, this.toDate);
    },
    showCreateAnalysisModal() {
      this.isCreateAnalysisModalActive = true;
      if (this.analysisUpdater) {
        this.analysisUpdater(null, null, null, this.fromDate, this.toDate);
      }
    }
  },
  mounted() {
    this.syncFromQueryParams(MANAGED_QUERY_PARAMS);
    this.loadData();
  },
  watch: {
    device() {
      this.loadData();
    }
  }
};
</script>
<style scoped>
.detail-tables {
  display: flex;
  justify-content: flex-start;
}
.detail-tables .b-table {
  display: inline-block;
  margin: 0 20px;
  flex-grow: 1;
}

.summary {
  display: flex;
  flex-direction: "row";
  justify-content: "space-between";
  flex-wrap: "wrap";
}

.summary-value {
  margin-left: 5px;
}
</style>
